<template>
  <div class="box">
    <img :src="banner" alt="市级资源" style="margin-top: 20px;" width="100%">
    <!--  资源板块  -->
    <div class="plate-box flex_row m_tb_20">
      <div class="plate br_10 fs_18 bg_fff p_in_10 pointer"
           :class="{'select-classify':item.id === selectedClassify}"
           v-for="(item,index) of classifyList"
           :key="index"
           @click="classify_click(item.id)">
        <img :src="item.iconImg" :alt="item.name" width="65px">
        <div class="m_tb_10">{{ item.name }}</div>
      </div>
    </div>
    <!--  目录筛选  -->
    <div>
      <FilterDirectory
          :classifyId="selectedClassify"
          @clickClassifyItem="classifyItem_click"
          :contentsId="contentsId"/>
    </div>
    <!--  资源列表  -->
    <div v-loading="loading">
      <div class="resource-list flex_row_wrap" v-if="resourceList.length!==0">
        <Resource class="m_b_20 resource-item"
                  v-for="resource of resourceList"
                  :key="resource.id"
                  :resourceData="resource"/>
      </div>
      <el-empty v-else description="暂无内容"></el-empty>
      <!--  分页  -->
      <div class="flex_row" style="justify-content: center;">
        <el-pagination
            class="m_b_20"
            background
            layout="prev, pager, next"
            @current-change="current_change"
            :current-page.sync="paginationParams.currentPage"
            :page-size="paginationParams.size"
            :total="paginationParams.total"
            hide-on-single-page>
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>
import img from 'assets/image/video.png'
import dangshiImg from 'assets/image/Group 313.png'
import shishengImg from 'assets/image/Group 315.png'
import chuangxinImg from 'assets/image/Group 316.png'
import jiaoyuImg from 'assets/image/Group 317.png'
import shengwuImg from 'assets/image/Group 318.png'
import zhuanyeImg from 'assets/image/Group 320.png'
import banner from 'assets/image/Group 639.png'
import Resource from '../components/resource/Resource'
import FilterDirectory from '../components/resource/FilterDirectory'
// eslint-disable-next-line no-unused-vars
import {
  getResourceClassify,
  getResourceList,
} from "../../../api/resourceCenter";
import {sessionSet} from "../../../utils/local";

export default {
  name: 'MunicipalLevel',
  components: {
    Resource,
    FilterDirectory
  },
  data: () => {
    return {
      img,
      banner,
      iconList: [
        {
          name: '党史学习',
          id: '1547048021949165570',
          img: dangshiImg
        },
        {
          name: '师生活动',
          id: '1547052760426360833',
          img: shishengImg
        },
        {
          name: '创新课程',
          id: '1547134841730994177',
          img: chuangxinImg
        },
        {
          name: '教育专题',
          id: '1547137428274307073',
          img: jiaoyuImg
        },
        {
          name: '生物实验操作教学示例',
          id: '1547138220414754818',
          img: shengwuImg
        },
        {
          name: '专业提升',
          id: '1547138781738459137',
          img: zhuanyeImg
        },
      ],
      // 目录列表
      classifyList: [],
      // 资源列表
      resourceList: [],
      // 请求参数
      resourceParams: {
        // 资源分类id
        classifyId: '',
        // 资源目录ids
        contentIds: '',
        // 资源目录id
        contentsId: '',
        // 当前页
        current: 1,
        // 资源ids
        ids: '',
        // 资源标签
        label: '',
        // 资源名称
        name: '',
        // 每页数量
        size: 12,
        // 资源排序 1浏览最多(热门推荐) 2点赞最多 3收藏最多(精品推荐)
        sortDiv: '',
        // 资源类型
        type: '',
        // 1国家资源2省资源3市资源4区资源5空中课堂
        level: 3
      },
      // 选中的目录
      selectedClassify: null,
      // 子目录
      childClassifyList: {},
      // 选中的子目录
      selectedChildClassify: '',
      // 分页
      paginationParams: {
        currentPage: 1,
        size: 12,
        total: 0
      },
      contentsId: '',
      loading: false,
    }
  },
  async mounted() {
    let params = this.$route.params
    // 获取目录
    await getResourceClassify({level: 3}).then(res => {
      res.data.forEach(classifyItem => {
        this.iconList.forEach(iconItem => {
          if (classifyItem.id === iconItem.id) {
            classifyItem.iconImg = iconItem.img
          }
        })
      })

      this.classifyList = res.data
    })

    this.resourceParams.classifyId = this.selectedClassify = this.classifyList[0].id

    if (params.id) {
      this.resourceParams.classifyId = this.selectedClassify = params.id
    }

    this.getResourceList()
  },
  methods: {
    // 获取资源列表
    getResourceList() {
      sessionSet('searchParams', this.resourceParams)

      this.loading = true
      getResourceList(this.resourceParams).then(res => {
        this.resourceList = res.data.records
        this.paginationParams.total = res.data.total
        this.loading = false
      })
    },
    // 切换大目录
    classify_click(classifyId) {
      this.resourceParams.contentsId = ''
      this.resourceParams.current = 1
      this.paginationParams.currentPage = 1
      this.resourceParams.classifyId = this.selectedClassify = classifyId
      this.contentsId = '1'
      this.getResourceList()
    },
    //点击子目录
    classifyItem_click(id) {
      this.paginationParams.currentPage = 1
      this.resourceParams.current = 1
      this.resourceParams.contentsId = id
      this.resourceParams.classifyId = ''
      this.contentsId = ''
      this.getResourceList()
    },
    // 切换分页
    current_change(nowCurrent) {
      this.resourceParams.current = nowCurrent
      this.getResourceList()
    },
  }
}
</script>

<style scoped>
.box {
  width: 1200px;
  margin: auto;
}

.plate-box {
  justify-content: space-between;
}

.plate {
  width: 136px;
  height: 144px;
  text-align: center;
}

.resource-list {
  /*justify-content: space-between;*/
}

.resource-item {
  margin-right: 16px;
}

.resource-item:nth-child(4n) {
  margin-right: 0;
}

.select-classify {
  background-color: #e3f4f0;
}
</style>