<template>
  <div
    v-if="classifies.length !== 0"
    class="classify-box p_in_20 bg_fff br_10 m_b_20 flex_justify_between"
    v-loading="classifyLoading">
    <div>
      <div
          class="m_tb_10 flex_row_wrap"
          v-for="(parentItem, index) of classifies"
          :key="parentItem.id">
        <div
            class="fs_14 m_r_10 m_tb_5 classify-item pointer"
            :class="{ selected: selectClassifies[index] === childItem.id }"
            @click="
          classifyItem_click(childItem.id, index, childItem.name, childItem)
        "
            v-for="childItem of parentItem"
            :key="childItem.id">
          {{ childItem.name }}
        </div>
      </div>
    </div>

    <div class="flex_row">
      <div
          class=""
          v-show="
            this.$route.query.scene != 'noSearch' &&
            selected != 5 &&
            this.$route.query.scene != 'onlineTeaching'
          ">
        <el-input
            placeholder="输入内容进行资源搜索"
            v-model="search_key"
            :clearable="true"
            class="input-with-select"
            @keyup.enter.native="search_but_click">
          <el-button
              slot="append"
              icon="el-icon-search"
              style="
                background-color: #a2c8ca;
                color: #fff;
                border: 1px #a2c8ca solid;
              "
              @click="search_but_click">
            搜索
          </el-button>
        </el-input>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getSubClassifyById,
  getRegionalSubClassifyById,
} from '../../../../api/resourceCenter';
import { sessionSetItem, sessionGet } from '../../../../utils/local';

export default {
  name: 'FilterDirectory',
  props: {
    classifyId: {
      type: String,
      default: null,
    },
    type: {
      type: Number,
      default: 1,
    },
    contentsId: {
      type: String,
      default: null,
    },
    labels: {
      type: String,
      default: null,
    },
    local: {
      type: String,
      default: null,
    },
    worksDisplay: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    classifyId: {
      handler(newVal) {
        // console.log(newVal);
        //热门专区特殊处理
        if (newVal) {
          if (newVal == '1111111111111111112') {
            // console.log(231232);
            this.childClassifyParams = {
              id: newVal,
              type: this.type,
            };
            // this.classifies = [];
            // this.selectClassifies = [];
            this.getChildClassifyHot();
          } else {
            this.childClassifyParams = {
              id: newVal,
              type: this.type,
            };
            this.classifies = [];
            this.selectClassifies = [];
            this.getChildClassify();
          }
        }
      },
      immediate: true,
    },
    contentsId: {
      handler(newVal) {
        if (newVal) {
          this.selectClassifies = [];
          this.classifies = this.classifies.slice(0, 1);
        }
      },
      immediate: true,
    },
    labels: {
      handler(newVal) {
        if (newVal) {
          this.label = newVal;
        }
      },
      immediate: true,
    },
    worksDisplay: {
      handler(val) {
        if (val) {
          this.selectClassifies[0] = '1518399756408508417';
          setTimeout(() => {
            this.classifyItem_click('1518399756408508417', 0);
          }, 500);
        }
      },
    },
  },
  data: () => {
    return {
      // 目录
      classifies: [],
      label: '',
      // 选中的目录
      selectClassifies: [],
      // 子目录参数
      childClassifyParams: {
        id: '',
        type: '',
      },
      classifyDate: [
        {
          title: '德育',
          labels: [
            { name: '心理健康教育', id: '-1' },
            { name: '理想信念教育', id: '-2' },
            { name: '法制教育', id: '-3' },
            {
              name: '安全教育',
              id: '-4',
            },
            { name: '综合', id: '-5' },
            { name: '生态', id: '-6' },
          ],
        },
        {
          title: '体育',
          labels: [
            {
              name: '运动技能',
              id: '-1',
              children: [
                { name: '足球', id: '-1' },
                { name: '排球', id: '-2' },
                { name: '跳绳', id: '-3' },
                {
                  name: '其他运动技能',
                  id: '-4',
                },
              ],
            },
            { name: '体育与健康', id: '-2' },
            { name: '阳光赛场', id: '-3' },
          ],
        },
        {
          title: '美育',
          labels: [
            { name: '美育课程', id: '-3' ,
              children: [
                { name: '小学', id: '-1' },
                { name: '初中', id: '-2' },
              ],
            },
            { name: '活动作品展示', id: '1628999007047049217' },
          ],
        },
        {
          title: '劳动教育',
          labels: [{ name: '综合实践', id: '-1' }],
        },
      ],
      classifyLoading: false,
      search_key:''
    };
  },
  methods: {
    //获取热门专区子目录
    getChildClassifyHot(id, index) {
      this.classifyLoading = true;
      let datas = { id: id, type: 2 };

      getSubClassifyById(datas).then((res) => {
        if (res.data.length !== 0) {
          // console.log();
          let result = [];
          res.data.map((item) => {
            item.remarks1 = '000000000';
            result.push(item);
          });
          this.classifies.push(result);

          // this.classifies.push(res.data);
        }
        this.classifyLoading = false;
      });
    },
    // 获取子目录
    getChildClassify() {
      let apiAddress =
        this.local == '区级资源'
          ? getRegionalSubClassifyById
          : getSubClassifyById;
      this.classifyLoading = true;
      //智育、劳动教育特殊处理（20221229）
      if (this.labels == '智育' || this.labels == '劳动教育') {
        return false;
      }
      /* 智育栏目通过标签查询特殊处理（20221022）*/
      if (this.labels == '智育' && this.childClassifyParams.type == 1) {
        this.childClassifyParams.id = '1541601152817176578';
      }
      apiAddress(this.childClassifyParams).then((res) => {
        if (res.data.length !== 0) {
          this.classifies.push(res.data);
        } else {
          //点击区域热门资源外的其他大类时通过标签判断显示的二级分类（2022-10-21）
          this.classifyDate.forEach((val) => {
            if (val.title == this.label) {
              this.classifies.push(val.labels);
            }
          });
        }
        // console.log(this.classifies);
        this.classifyLoading = false;
      });
    },
    clKey(){
      // console.log('chufa1');
      this.search_key = '';
      sessionSetItem('routeparams', 'searchKey', '');
    },
    search_but_click() {
      sessionSetItem('routeparams', 'searchKey', this.search_key);
      let id = sessionGet('routeparams').childrenId;
      let index = sessionGet('routeparams').indexSub;
      let name = sessionGet('routeparams').nameSub;
      let item = sessionGet('routeparams').itemSub;
      this.classifyItem_click(id, index, name, item,'but');
    },
    classifyItem_click(id, index, name, item,ch) {
      if('but' != ch){
        this.clKey();
      }
      sessionSetItem('routeparams', 'childrenId', id);
      sessionSetItem('routeparams', 'indexSub', index);
      sessionSetItem('routeparams', 'nameSub', name);
      sessionSetItem('routeparams', 'itemSub', item);
      this.$emit('selected', name);
      // console.log(id, index, name, item);
      if (id > 0) {
        //实用小工具特殊处理
        if (id == '1111111111111111112' || item.remarks1 == '000000000') {
          this.getChildClassifyHot(id, index);
        }
        this.classifies = this.classifies.slice(0, index + 1);
        this.selectClassifies = this.selectClassifies.slice(0, index + 1);
        this.selectClassifies[index] = id;
        this.childClassifyParams = {
          id,
          type: 2,
        };
        // console.log(this.childClassifyParams);
        // this.getChildClassify();
        this.$emit('clickClassifyItem', id, '', item);
      } else {
        /* 通过标签查询分类（20221021)*/
        let lab = this.labels + ',' + name;
        this.classifies = this.classifies.slice(0, index + 1);
        this.selectClassifies = this.selectClassifies.slice(0, index + 1);
        this.selectClassifies[index] = id;
        if (name == '运动技能') {
          this.classifies.push(this.classifyDate[1].labels[0].children);
        }
        if (name == '美育课程') {
          this.classifies.push(this.classifyDate[2].labels[0].children);
        }
        this.$emit('clickClassifyItem', -1, lab.split(',')[0] + ',' + name);
      }
    },
  },
};
</script>

<style scoped>
.classify-box {
  min-height: 97px;
}

.classify-item {
  background: rgba(18, 194, 148, 0.05);
  text-align: center;
  border-radius: 30px;
  line-height: 15px;
  padding: 6px 20px;
  color: rgba(0, 0, 0, 0.6);
}

.classify-item:hover {
  background-color: #3d8787;
  color: #fff;
}

.selected {
  color: #fff;
  background-color: #3d8787;
}
</style>
