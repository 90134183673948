<template>
  <div
    class="recourse-box br_10 bg_fff"
    @click="toResourceDetail_click(resourceData)"
  >
    <img class="img" :src="imgToRespomse(resourceData.coverUrl)" alt="" />
    <div class="fs_16 p_in_10 text_hidden fw_bold">{{ resourceData.name }}</div>
    <div class="tag fs_14 c_fff flex_row" style="justify-content: center">
      <img
        class="m_r_10"
        :src="typeJSON[resourceData.type].img"
        alt=""
        style="height: 14px; margin-top: 6px"
      />
      <span>{{ typeJSON[resourceData.type].name }}</span>
    </div>
  </div>
</template>

<script>
import img from "@/assets/image/5g.jpg";
import videoImg from "assets/image/resource/video.png";
import docImg from "assets/image/resource/doc.png";
import voiceImg from "assets/image/resource/voice.png";
import picImg from "assets/image/resource/pic.png";
import { imgToRespomse } from "../../../../utils/imgToResponse";
import { sessionGet } from "../../../../utils/local";

export default {
  name: "Resource",
  props: {
    resourceData: {
      type: Object,
      default: () => ({}),
    },
    routeParams: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      img,
      videoImg,
      //1文档、2音频、3视频、4图片
      typeJSON: {
        1: {
          img: docImg,
          name: "文档",
        },
        2: {
          img: voiceImg,
          name: "音频",
        },
        3: {
          img: videoImg,
          name: "视频",
        },
        4: {
          img: picImg,
          name: "图片",
        },
      },
    };
  },
  methods: {
    imgToRespomse(url) {
      return imgToRespomse(url);
    },
    toResourceDetail_click(data) {
      this.$router.push({
        path: "/resource/details",
        query: {
          id: data.id,
          scene: this.$route.name,
          name: this.routeParams.name,
        },
      });
    },
  },
};
</script>

<style scoped>
.recourse-box {
  /*width: 290px;*/
  width: 24%;
  overflow: hidden;
  position: relative;
}

.recourse-box:hover {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.1);
}

.img {
  width: 101%;
  height: 167px;
  object-fit: cover;
}

.tag {
  position: absolute;
  width: 80px;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  top: 0;
  right: 0;
  border-radius: 0 10px 0 10px;
  line-height: 26px;
}
</style>
